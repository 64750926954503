'use strict';

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

const body = document.querySelector('body');
const siteWrapper = document.querySelector('.site-wrapper');

// Main navigation toggle

// Cookery class modals

const toggles = document.querySelectorAll(".cookery-class__toggle");
const overlay = document.querySelector(".cookery-class-overlay");
const closeOverlayBtns = document.querySelectorAll(".close-modal");

toggles.forEach(function (toggle) {
  toggle.addEventListener("click", function () {
    // Open overlay

    overlay.classList.add("is-open");
    body.classList.add('modal-is-open');

    const currentModalData = document.querySelector(
      '.cookery-class-modal[data-modal="' + this.dataset.modalToggle + '"]'
    );

    const openModal = document.querySelector(".cookery-class-modal.is-visible");
    const activeToggle = document.querySelector(".cookery-class__toggle.is-selected");

    if (openModal && activeToggle) {
      openModal.classList.remove("is-visible");
      activeToggle.classList.remove("is-selected");
    }

    currentModalData.classList.add("is-visible");
    this.classList.add("is-selected");
  });
});

closeOverlayBtns.forEach(function (btn) {
  btn.addEventListener("click", function () {
    const openModal = document.querySelector(".cookery-class-modal.is-visible");
    const activeToggle = document.querySelector(".cookery-class__toggle.is-selected");

    overlay.classList.remove("is-open");
    body.classList.remove('modal-is-open');
    openModal.classList.remove("is-visible");
    activeToggle.classList.remove("is-selected");
  });
});



// Detect flexbox gap property

function checkFlexGap() {
  // create flex container with row-gap set
  var flex = document.createElement("div");
  flex.style.display = "flex";
  flex.style.flexDirection = "column";
  flex.style.rowGap = "1px";

  // create two, elements inside it
  flex.appendChild(document.createElement("div"));
  flex.appendChild(document.createElement("div"));

  // append to the DOM (needed to obtain scrollHeight)
  document.body.appendChild(flex);
  var isSupported = flex.scrollHeight === 1; // flex container should be 1px high from the row-gap
  flex.parentNode.removeChild(flex);

  return isSupported;
}

if (checkFlexGap()) {
  body.classList.add("flexbox-gap");
} else {
  body.classList.add("no-flexbox-gap");
}